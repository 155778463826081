<template>
  <div
    class="v-arora-group--barad-nimras"
    data-test-id="menu-groups-link-container"
    v-sticky-top="{ refresh: false, onRefresh: updateSticky }"
  >
    <arora-swiper-slider
      class-name="v-header-address-slider"
      enable-scrollbar
      :items="menuStore.GroupsMainPage"
      :space-between="isSmall ? 20 : 32"
      max-items="auto"
      pagination-type="none"
    >
      <template #item="group: Group">
        <div
          class="v-arora-group--barad-nimras--single v-pointer"
          :class="[
            selectedGroupIdLocal === group.ID
              ? 'v-arora-group--barad-nimras--single__selected'
              : 'v-arora-group--barad-nimras--single__default'
          ]"
          @click="() => (selectedGroupIdLocal = group.ID)"
        >
          <div
            class="v-arora-group--barad-nimras--single-image"
            :style="`width: ${iconSize}px; height: ${iconSize}px`"
          >
            <arora-nuxt-image
              v-if="group.ImageIcon && imageExists(group.ImageIcon)"
              disable-lazy
              :alt="group.Name"
              :image="group.ImageIcon"
            />
            <arora-nuxt-image
              v-else
              disable-lazy
              :alt="group.Name"
              :image="group.Image"
            />
          </div>
          <span
            class="v-arora-group--barad-nimras-caption"
            :href="group.Link"
            data-test-id="menu-groups-link"
            v-html="sanitize(group.Name)"
          />
        </div>
      </template>
    </arora-swiper-slider>
  </div>
</template>

<script setup lang="ts">
import type { Group } from '~types/menuStore'

import { type GUID, useWindowSize, vStickyTop } from '@arora/common'
import gsap from 'gsap'

const appConfig = useAppConfig()
const menuStore = useMenuStore()

const { sanitize } = useI18nSanitized()
const { imageExists } = useImageInfo()

const { isSmall } = useWindowSize()

const selectedGroupIdLocal = computed<GUID | undefined>({
  get: () => menuStore.CurrentGroupId,
  set: (value) => {
    const foundGroup = menuStore.GroupsMainPage.find((g) => g.ID === value)

    navigateTo(foundGroup ? foundGroup.Link : appConfig.VueSettingsPreRun.Links.MainLink)
  }
})

const animationDuration = 0.2

let currentSticky = false

const iconSize = computed<number>(() => (isSmall.value ? 30 : 60))
const marginSearchRight = computed<number>(() => (isSmall.value ? 16 : 32))
function updateSticky(isSticky: boolean): void {
  if (isSticky !== currentSticky) {
    currentSticky = isSticky

    const searchElement = document.querySelector('.v-header-groups--search__BaradNimras')

    const tl = gsap.timeline()

    tl.add('scrollAnim', '+=0')

    if (searchElement) {
      tl.to(
        searchElement,
        {
          duration: animationDuration,
          ease: 'sine.inOut',
          marginRight: isSticky ? 5 : marginSearchRight.value
        },
        'scrollAnim'
      )

      tl.to(
        searchElement.querySelector('svg'),
        {
          duration: animationDuration,
          ease: 'sine.inOut',
          marginTop: isSticky ? 10 : 0,
          height: isSticky ? 30 : iconSize.value,
          width: isSticky ? 30 : iconSize.value
        },
        'scrollAnim'
      )

      tl.to(
        searchElement.querySelector('.v-arora-group--barad-nimras-caption'),
        {
          duration: animationDuration,
          ease: 'sine.inOut',
          maxHeight: isSticky ? 0 : iconSize.value,
          opacity: isSticky ? 0 : 1
        },
        'scrollAnim'
      )
    }

    if (!isSmall.value) {
      tl.to(
        '.v-small-basket--button',
        {
          duration: animationDuration,
          ease: 'sine.inOut',
          height: isSticky ? 50 : 90,
          marginBottom: isSticky ? 0 : '1rem',
          flexDirection:
            !isSticky && appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras'
              ? 'column'
              : 'row'
        },
        'scrollAnim'
      )

      tl.to(
        '.v-small-basket--button--icon',
        {
          duration: animationDuration,
          ease: 'sine.inOut',
          height: isSticky ? 25 : 40,
          width: isSticky ? 25 : 40
        },
        'scrollAnim'
      )
    }

    tl.to(
      '.v-arora-group--barad-nimras--single-image',
      {
        duration: animationDuration,
        ease: 'sine.inOut',
        opacity: isSticky ? 0 : 1,
        height: isSticky ? 10 : iconSize.value,
        width: isSticky ? 10 : iconSize.value,
        overflow: isSticky ? 'hidden' : 'visible'
      },
      '>'
    )

    tl.play()
  }
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-arora-group--barad-nimras {
  margin: 0 0 10px;

  .swiper-slide {
    width: fit-content;
  }
}

.v-arora-group--barad-nimras--single {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60px;
  user-select: none;
  margin: 10px 0 0;
  @include mixins.trans;

  .v-arora-group--barad-nimras-caption {
    padding: 6px 0;
    text-align: center;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    font-size: 1.15rem;
    font-family: variables.$FontFamilyMenuGroups;
    color: variables.$HeaderColor;
  }

  &__selected {
    svg {
      stroke: variables.$PrimaryBackgroundColor;
    }

    .v-arora-group--barad-nimras-caption {
      font-weight: 600;
      color: variables.$PrimaryBackgroundColor;
    }
  }

  &:hover {
    svg {
      stroke: variables.$PrimaryBackgroundColorDarken;
    }

    .v-arora-group--barad-nimras-caption {
      color: variables.$PrimaryBackgroundColorDarken;

      @include mixins.sm {
        color: variables.$HeaderColor;
      }
    }
  }

  @include mixins.sm {
    width: fit-content;
  }
}
</style>
